import { isValidUrl } from '@helpers/url';
import * as yup from 'yup';
import moment from 'moment';
import { generateSchemaFromFields } from '@helpers/form';

export const fields = {
  name: {
    path: 'name',
    rule: yup.string(),
  },
  language: {
    path: 'language',
    rule: yup.string(),
  },
  weighting: {
    path: 'weighting',
  },
  click_url: {
    path: 'click_url',
    rule: yup
      .string()
      .test('is-url-valid', 'Please provide a valid URL', isValidUrl),
  },
  impression_tracking_pixel: {
    path: 'pixels',
    rule: yup.array().of(
      yup.object().shape({
        value: yup
          .string()
          .test('is-url-valid', 'Please provide a valid URL', isValidUrl),
      })
    ),
  },
  start_date: {
    path: 'start_date',
    defaultValue: '',
    rule: yup
      .mixed()
      .test(
        'both-required',
        'Start datetime is required if end datetime is set',
        function (value) {
          const end_date = this.parent.end_date;
          return !end_date || !!value;
        }
      )
      .test(
        'start-before-end',
        'Start datetime must be before end datetime',
        function (value) {
          const { end_date } = this.parent;
          if (!value || !end_date) return true;
          return moment(value) < moment(end_date);
        }
      ),
  },
  end_date: {
    path: 'end_date',
    defaultValue: '',
    rule: yup
      .mixed()
      .test(
        'both-required',
        'End datetime is required if start datetime is set',
        function (value) {
          const start_date = this.parent.start_date;
          return !start_date || !!value;
        }
      )
      .test(
        'end-after-start',
        'End datetime must be after start datetime',
        function (value) {
          const { start_date } = this.parent;
          if (!value || !start_date) return true;
          return moment(value) > moment(start_date);
        }
      ),
  },
  showClickUrl: {
    path: 'showClickUrl',
  },
  showImpressionTrackingPixel: {
    path: 'showImpressionTrackingPixel',
  },
  showDateTime: {
    path: 'showDateTime',
  },
} as const;

export const validationSchema = generateSchemaFromFields(fields);
