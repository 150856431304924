import { defaultAdGroupName } from '@apis/adGroups';
import { useCopy } from '@components/hooks/copy';
import { Themes } from '@constants/themes';
import { Avatar, Link, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React, { createContext } from 'react';
import * as yup from 'yup';
import { entityStatus } from '../../../../components/CampaignWizard/constants';
import {
  endDateValidation,
  endTimeValidation,
  startDateSimpleValidation,
  startTimeSimpleValidation,
} from '../../../../components/CampaignWizard/validations';
import { allValue } from './Audience/constants';
import { distinctNames } from '../GeoTargeter/constants';
import {
  TABS,
  weightingRotationOptions,
} from './AdvancedSubflows/AdvancedCreative/constants';
import { AvatarImageStyled } from './styles';
import { Bundle } from '@local-types/bundle';
import {
  generateSchemaFromFields,
  getFormDefaultValues,
} from '@helpers/form';
import type {
  DaypartingOption,
  FrequencyCapOption,
  Pacing,
  PacingBehavior,
  PacingCatchup,
} from '@local-types/group';
import type { EditingField, Nullable } from '@local-types/general';
import type { GeoItem, RawGeoItems } from '../GeoTargeter';
import type { Audience } from '@local-types/audience';
import type { AdvancedInventoryData } from '@local-types/inventory-targeting';
import type { Creative } from '@local-types/creative';

export const AdvancedContext = createContext({
  audience: [],
  inventory: [],
  library: [],
  onCreateFile: () => {},
  customInventory: [],
});

export const zipCodesEmptyValue = {
  data: [],
  include: true,
};

export const Copies = {
  [Themes.DEFAULT]: {
    addGeography: 'Add geography',
    addZips: 'New bulk entry',
    advancedDeliveryOptionsLabel: 'Advanced ad group delivery options',
    advancedInventoryOptionsLabel: 'Advanced inventory targeting options',
    advancedTargetingOptionsLabel: 'Advanced audience targeting options',
    bidStrategyHeader: 'Select a bid strategy',
    bidStrategyTitle: 'Bid strategy',
    customInventoryDescription:
      'You select which channels and genres to include or exclude. Best for \
      experienced and high-volume CTV advertisers with direct supply \
      relationships, looking to leverage tvScientific to take their buying \
      to the next level.',
    frequencyCapLabel: 'Frequency cap:',
    inventoryLabel: 'Select an inventory option',
  },
  [Themes.NBCU]: {
    addGeography: 'Add Geography',
    addZips: 'New Bulk Entry',
    advancedDeliveryOptionsLabel: 'Advanced Ad Group Delivery Options',
    advancedInventoryOptionsLabel: 'Advanced Inventory Targeting Options',
    advancedTargetingOptionsLabel: 'Advanced Audience Targeting Options',
    bidStrategyHeader: 'Select a Bid Strategy',
    bidStrategyTitle: 'Bid Strategy',
    customInventoryDescription:
      'You select which channels and genres to include or exclude. Best for \
      experienced and high-volume CTV advertisers with direct supply \
      relationships, looking to leverage Peacock Ad Manager to take their \
      buying to the next level.',
    frequencyCapLabel: 'Frequency Cap:',
    inventoryLabel: 'Select an Inventory Option',
  },
};

export const getInventoryOptions = ({
  customInventoryItems = [],
  onAdvancedCustomInventory,
  isCustomInventoryExpanded,
  setIsCustomInventoryExpanded,
  isDisplay = false,
  domain,
  bundles,
}: {
  customInventoryItems?: Bundle[];
  onAdvancedCustomInventory?: () => void;
  isCustomInventoryExpanded?: boolean;
  setIsCustomInventoryExpanded?: () => void;
  isDisplay?: boolean;
  domain: Record<string, boolean>;
  bundles: Bundle[];
}) => {
  const Copy = useCopy(Copies);

  if (isDisplay) {
    return [
      {
        value: 'premiumDisplay',
        label: 'Premium Display',
        description:
          'Brand-safe and cost-effective website and app display inventory, \
        to extend your reach',
      },
    ];
  }

  return [
    ...(!domain.peacock
      ? [
          {
            value: 'performanceOptimized',
            label: 'Performance Optimized',
            description:
              'Inventory is organized by bundle, content, network, and app \
              type for narrow content or publisher targeting. Best for \
              performance advertisers looking to maximize exposure but drive \
              down CPA/CAC, while maintaining brand safety and prioritizing \
              target audience above content.',
            infoText: (
              <Typography component="span" variant="body2">
                <a
                  href="https://help.tvscientific.app/en/articles/7211806-supply-101"
                  target="_blank"
                  title=""
                  rel="noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  Learn more about Performance Optimized inventory
                </a>
              </Typography>
            ),
            locked: !bundles?.some(({ recommended }) => recommended),
            unlockedContent: <Stack />,
            recommended: false,
            lockedTooltipInfo:
              'We need more historical performance data to make inventory recommendations for this Ad Group.',
            lockedInfo:
              'Recommendations not available for this Campaign, please select Maximum Reach or Custom Inventory.',
            recommendedInfo:
              'Recommended based on your campaign objective and bid strategy',
          },
        ]
      : []),
    {
      value: domain.peacock ? 'padman-premium-reach' : 'premium-reach',
      label: 'Maximum Reach',
      description:
        'Brand safe, high quality publishers, with lower costs due to a broad \
        range of living room quality content. Best for broad branding \
        advertisers that are targeting a wider audience.',
      infoText: 'Learn more about Maximum Reach inventory',
    },
    {
      value: 'bundles',
      label: 'Custom Inventory',
      description: (
        <Stack
          onClick={onAdvancedCustomInventory}
          style={{ marginBottom: 2.5 }}
          data-testid="custom-inventory-option"
        >
          <Typography component="span" variant="body2">
            {Copy.customInventoryDescription}
          </Typography>
        </Stack>
      ),
      unlockedContent: (
        <Stack
          onClick={onAdvancedCustomInventory}
          spacing={1}
          sx={{ marginBottom: 2.5 }}
        >
          <Stack direction="row" spacing={1}>
            {customInventoryItems
              .slice(
                0,
                isCustomInventoryExpanded ? customInventoryItems.length : 2
              )
              .map(i => (
                <Stack
                  alignItems="center"
                  direction="row"
                  spacing={1}
                  key={i.id}
                >
                  <Avatar
                    sx={{
                      bgcolor: i.icon?.url ? 'transparent' : 'grey.8',
                    }}
                    variant="square"
                  >
                    {i.icon ? (
                      <AvatarImageStyled src={i.icon?.url} />
                    ) : (
                      <span />
                    )}
                  </Avatar>
                  <Typography variant="h3">{i.display_name}</Typography>
                </Stack>
              ))}
          </Stack>
          {!isCustomInventoryExpanded && customInventoryItems.length > 2 ? (
            <Link
              sx={{
                cursor: 'pointer',
              }}
              onClick={e => {
                e.preventDefault();
                setIsCustomInventoryExpanded?.();
              }}
            >
              + {customInventoryItems.length - 2} more...
            </Link>
          ) : null}
        </Stack>
      ),
    },
  ];
};

const requiredMessage = 'Required field';

export const fields = {
  timezone: {
    path: 'timezone',
    defaultValue: 'est',
  },
  status: {
    path: 'status',
    defaultValue: entityStatus.draft,
  },
  draft: {
    path: 'draft',
    defaultValue: true,
  },
  description: {
    path: 'description',
    defaultValue: '',
  },
  campaign: {
    path: 'campaign',
    defaultValue: null,
  },
  advancedAudience: {
    path: 'advancedAudience',
    defaultValue: [] as Audience[],
  },
  audienceExtension: {
    path: 'audienceExtension',
    defaultValue: false,
  },
  zipcodes: {
    path: 'zipcodes',
    defaultValue: zipCodesEmptyValue,
  },
  advancedInventory: {
    path: 'advancedInventory',
    defaultValue: {
      os: {
        blacklist: false,
        ids: [],
      },
      device: {
        blacklist: false,
        ids: [],
      },
    } as AdvancedInventoryData,
  },
  adGroupName: {
    path: 'name',
    defaultValue: '',
    rule: yup.string().nullable().required(requiredMessage),
  },
  adGroupBudget: {
    path: 'daily_budget',
    defaultValue: 0,
    rule: yup
      .number()
      .transform((value, originalValue) => {
        return originalValue === '' ? null : value;
      })
      .nullable()
      .test('is-not-zero', requiredMessage, value => {
        if (!value) return false;

        return parseFloat(String(value)) !== 0;
      }),
  },
  startDate: {
    path: 'start_date',
    defaultValue: moment().startOf('day'),
    rule: startDateSimpleValidation('end_date'),
  },
  endDate: {
    path: 'end_date',
    defaultValue: null,
    rule: endDateValidation('start_date'),
    clearable: true,
  },
  startTime: {
    path: 'start_time',
    defaultValue: moment().startOf('day'),
    rule: startTimeSimpleValidation('start_date', 'end_date', 'end_time'),
  },
  endTime: {
    path: 'end_time',
    defaultValue: null,
    rule: endTimeValidation('start_date', 'start_time', 'end_date'),
    clearable: true,
  },
  geo: {
    path: 'geo',
    defaultValue: [],
    rule: yup
      .array()
      .of(
        yup.object().shape({
          blacklist: yup.boolean(),
        })
      )
      .required(),
  },
  maxCPMBidEnabled: {
    path: 'bidStrategyMaxCPMBidEnabled',
    defaultValue: false,
  },
  maxCPMBid: {
    path: 'cpm',
    defaultValue: null,
  },
  targetCPA: {
    path: 'bidStrategyTargetCPA',
    defaultValue: 0,
  },
  social: {
    path: 'social',
    defaultValue: {
      age: [] as [number, number] | [],
      gender: allValue,
      income: [] as [number, number] | [],
    },
  },
  configureAdGroupFrequencyCapEnabled: {
    path: 'bidStrategyConfigureAdGroupFrequencyCapEnabled',
    defaultValue: false,
  },
  configureAdGroupFrequencyCap: {
    path: 'bidStrategyConfigureAdGroupFrequencyCap',
    defaultValue: [] as EditingField<FrequencyCapOption>[],
  },
  configureAdGroupDaypartingEnabled: {
    path: 'bidStrategyConfigureAdGroupDaypartingEnabled',
    defaultValue: false,
  },
  configureAdGroupDayparting: {
    path: 'bidStrategyConfigureAdGroupDayparting',
    defaultValue: [] as EditingField<DaypartingOption>[],
  },
  adGroupPacingEnabled: {
    path: 'adGroupPacingEnabled',
    defaultValue: false,
  },
  adGroupPacing: {
    path: 'adGroupPacing',
    defaultValue: 'DAILY' as Nullable<Pacing>,
  },
  adGroupPacingBehavior: {
    path: 'adGroupPacingBehavior',
    defaultValue: 'EVEN' as Nullable<PacingBehavior>,
  },
  adGroupPacingCatchupBehavior: {
    path: 'adGroupPacingCatchupBehavior',
    defaultValue: 'EVEN' as Nullable<PacingCatchup>,
  },
  inventoryOption: {
    path: 'inventory_option',
    defaultValue: 'premium-reach',
  },
  advancedCustomInventory: {
    path: 'bundles',
    defaultValue: [] as Bundle[],
    rule: yup.array().when('inventory_option', {
      is: 'bundles',
      then: yup
        .array()
        .required('At least one custom inventory option should be selected')
        .min(1, 'At least one custom inventory option should be selected'),
    }),
  },
  adGroupBidStrategy: {
    path: 'bid_strategy',
    defaultValue: null,
    rule: yup.number().nullable().required('Please select a bid strategy'),
  },
  adGroupBidStrategyEvent: {
    path: 'bid_strategy_event',
    defaultValue: null,
  },
  creatives: {
    path: 'creatives',
    defaultValue: [] as Creative[],
  },
  weightingRotation: {
    path: 'creative_weighting_method',
    defaultValue: weightingRotationOptions.random,
  },
  geography: {
    path: 'geo',
    defaultValue: [] as GeoItem[],
  },
  inventory: {
    path: 'inventory',
    defaultValue: null,
  },
  targeting: {
    path: 'targeting',
    defaultValue: null,
  },
} as const;

export const inventoryTabs = [
  {
    label: 'Device Type',
    value: 'device',
    hint: 'Select the types of devices to target, such as mobile, tablet, \
    desktop, or connected TV.',
    settings: {
      includable: true,
      separateInclExcl: false,
      showSeparateSwitch: false,
    },
  },
  {
    label: 'Operating System',
    value: 'os',
    hint: 'Select the operating system such as iOS or Android to ensure your \
    ads are shown only on desired devices.',
    settings: {
      includable: true,
      separateInclExcl: false,
      showSeparateSwitch: false,
    },
  },
];

export const formSchema = generateSchemaFromFields(fields);

export const defaultValues = () => {
  const defaultVals = getFormDefaultValues(fields);
  // set ad group default name explictly here as it needs real-time update
  defaultVals[fields.adGroupName.path] = defaultAdGroupName();
  return defaultVals;
};

export const entireUSId = 'country.14135384517372290';

export const sections = {
  general: 'general',
  geography: 'geography',
  audience: 'audience',
  inventory: 'inventory',
  bidStrategy: 'bidStrategy',
  creative: 'creative',
} as const;

export const subSections = {
  [sections.general]: {
    nameAndBudget: 'nameAndBudget',
    dates: 'dates',
  },
} as const;

export const advancedModals = {
  general: 'general',
  creative: 'creative',
  audience: 'audience',
  inventory: 'inventory',
  bidStrategy: 'bid-strategy',
  customInventory: 'custom-inventory',
  geography: 'geography',
} as const;

export const entireUSValue = {
  id: 'entire',
  type: distinctNames.country,
  text: 'All of United States',
  name: 'Entire US',
};

export const defaultGeoValue = {
  [distinctNames.countries]: [
    {
      name: entireUSValue.name,
      type: entireUSValue.type,
      text: entireUSValue.text,
      blacklist: false,
    },
  ],
} as RawGeoItems;

export const creativesInfo = {
  [TABS.VAST_TAG]: (
    <Typography color="blue.1">
      We recommend using one creative per VAST tag. Linking multiple creatives
      will make it hard to understand which creative drives what performance.
    </Typography>
  ),
  [TABS.UPLOAD_ASSET]: (
    <Typography color="blue.1">
      We will check your video specs to ensure it meets minimum quality
      requirements. Issues will be automatically fixed in most cases.
    </Typography>
  ),
} as const;
