import React from 'react';
import { DataGrid } from '@v2/components/ui/DataGrid';
import { useAdGroupsTable } from '@components/containers/AdGroupsIndexPage/AdGroupsTableContext';
import { DateCellRenderer } from '@v2/components/ui/DataGrid/renderers/DateCellRenderer';
import { CurrencyCellRenderer } from '@v2/components/ui/DataGrid/renderers/CurrencyCellRenderer';
import { DotNotationValueGetter } from '@v2/components/ui/DataGrid/DotNotationValueGetter';
import { AdGroupStatusCellRenderer } from '@components/containers/AdGroupsIndexPage/renderers/AdGroupStatusCellRenderer';
import { AdGroupActionMenuCellRenderer } from '@components/containers/AdGroupsIndexPage/renderers/AdGroupActionMenuCellRenderer';
import { AdGroupSyncCheckRenderer } from '@components/containers/AdGroupsIndexPage/renderers/AdGroupSyncCheckRenderer';
import { defaultTheme_v2 } from '@themes/default_v2';
import { ThemeProvider, Stack, Button, Chip, Unstable_Grid2 as Grid2, Theme } from '@mui/material';
import { AdGroupNameCellRenderer } from '@components/containers/AdGroupsIndexPage/renderers/AdGroupNameCellRenderer';
import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';
import { AdGroupRealtimeDeliveryCellRenderer } from '@components/containers/AdGroupsIndexPage/renderers/AdGroupRealtimeDeliveryCellRenderer';
import { GridCellParams, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { AdGroupIdCellRenderer } from './renderers/AdGroupIdCellRenderer';
import { AdGroupBidStrategyCellRenderer } from './renderers/AdGroupBidStrategyCellRenderer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faCirclePause } from '@fortawesome/pro-regular-svg-icons';
import { useUser } from '@hooks/user';

export const AdGroupsTableV2 = () => {
  const {
    sortedAdGroups,
    isLoading,
    paginationModel,
    setPaginationModel,
    sortModel,
    setSortModel,
    rowCount,
    selectedAdGroupIds,
    setSelectedAdGroupIds,
    handleBulkEdit,
    handleBulkPause,
  } = useAdGroupsTable();
  const {
    realtimeDeliveryModal,
    releaseSyncCheckColumn,
    releaseBulkEditFields,
  } = useLDFlags();

  const { user } = useUser();

  const showOutOfSync = user?.is_tvsci_employee && releaseSyncCheckColumn;

  const handleSelectionChange = (
    newSelectionModel: GridRowSelectionModel
  ) => {
    setSelectedAdGroupIds(newSelectionModel as unknown as string[]);
  };

  const isBulkEditEnabled =
    Array.isArray(releaseBulkEditFields) &&
    releaseBulkEditFields.includes('AD_GROUP_BID_STRATEGY');

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 60,
      resizable: false,
      renderCell: AdGroupIdCellRenderer,
      hideable: false,
    },
    {
      field: 'name',
      headerName: 'Ad Group',
      minWidth: 280,
      renderCell: AdGroupNameCellRenderer,
      hideable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: AdGroupStatusCellRenderer,
      minWidth: 100,
      hideable: false,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    realtimeDeliveryModal && {
      field: 'realtime_delivery',
      headerName: '',
      width: 60,
      renderCell: AdGroupRealtimeDeliveryCellRenderer,
      resizable: false,
      hideable: false,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'action',
      headerName: '',
      width: 50,
      renderCell: AdGroupActionMenuCellRenderer,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'campaign_name',
      headerName: 'Campaign',
      width: 250,
    },
    {
      field: 'bid_strategy',
      headerName: 'Bid Strategy',
      width: 160,
      renderCell: AdGroupBidStrategyCellRenderer,
    },
    showOutOfSync && {
      field: 'ad_server_sync_status',
      headerName: 'Ad Server',
      renderCell: AdGroupSyncCheckRenderer,
      width: 120,
      resizable: false,
      hideable: false,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'start_date',
      headerName: 'Start Date',
      minWidth: 120,
      renderCell: DateCellRenderer,
    },
    {
      field: 'end_date',
      headerName: 'End Date',
      minWidth: 120,
      renderCell: (params: GridCellParams) =>
        // eslint-disable-next-line new-cap
        DateCellRenderer(params, 'No end date'),
    },
    {
      field: 'cpm',
      headerName: 'Max Bid',
      renderCell: CurrencyCellRenderer,
      align: 'right',
    },
    {
      field: 'budget',
      headerName: 'Budget',
      renderCell: CurrencyCellRenderer,
      align: 'right',
    },
    {
      field: 'stats_cache.total_spend',
      headerName: 'Spend',
      valueGetter: DotNotationValueGetter,
      renderCell: CurrencyCellRenderer,
      align: 'right',
    },
    {
      field: 'stats_cache.budget_remaining',
      headerName: 'Budget Remaining',
      minWidth: 160,
      valueGetter: DotNotationValueGetter,
      renderCell: CurrencyCellRenderer,
      align: 'right',
    },
    {
      field: 'stats_cache.visit_count',
      headerName: 'Visits',
      valueGetter: DotNotationValueGetter,
      align: 'right',
    },
    {
      field: 'stats_cache.cost_per_visit',
      headerName: 'Cost per Visit',
      valueGetter: DotNotationValueGetter,
      renderCell: CurrencyCellRenderer,
      minWidth: 120,
      align: 'right',
    },
  ].filter(Boolean);

  const actionButtonStyle = (theme: Theme) => ({
    textTransform: 'none',
    fontWeight: 400,
    p: 0,
    minWidth: 'auto',
    mr: theme.spacing(1),
    color: theme.palette.primary.main,
    fontSize: '1rem',
    '& svg': {
      color: theme.palette.primary.main,
      fontSize: '16px',
      width: '16px',
      height: '16px',
    },
  });

  return (
    <ThemeProvider theme={defaultTheme_v2}>
      <Stack sx={{ height: '100%' }} spacing={1}>
        {selectedAdGroupIds.length > 0 && (
          <Grid2 container spacing={2} alignItems="center">
            <Grid2 xs="auto">
              <Chip
                label={`${selectedAdGroupIds.length} of ${
                  rowCount ?? 0
                } selected`}
                sx={theme => ({
                  bgcolor: theme.palette.grey[100],
                  color: theme.palette.text.secondary,
                  fontWeight: 500,
                  '& .MuiChip-label': {
                    px: theme.spacing(2),
                    py: theme.spacing(0.625),
                  },
                })}
              />
            </Grid2>
            <Grid2 container xs="auto" spacing={1}>
              <Grid2>
                <Button
                  variant="text"
                  color="primary"
                  onClick={handleBulkEdit}
                  sx={actionButtonStyle}
                  startIcon={<FontAwesomeIcon icon={faPen} />}
                >
                  Edit
                </Button>
              </Grid2>
              <Grid2>
                <Button
                  variant="text"
                  color="primary"
                  onClick={handleBulkPause}
                  sx={actionButtonStyle}
                  startIcon={<FontAwesomeIcon icon={faCirclePause} />}
                >
                  Pause
                </Button>
              </Grid2>
            </Grid2>
          </Grid2>
        )}

        <DataGrid
          loading={isLoading}
          columns={columns}
          rows={sortedAdGroups}
          getRowId={(row: { id: string }) => row.id}
          slotProps={{
            row: {
              'data-testid': 'adgroup-row',
            },
          }}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          sortModel={sortModel}
          onSortModelChange={setSortModel}
          rowCount={rowCount ?? 0}
          checkboxSelection={isBulkEditEnabled}
          rowSelectionModel={selectedAdGroupIds}
          onRowSelectionModelChange={handleSelectionChange}
          keepNonExistentRowsSelected={true}
          disableRowSelectionOnClick={true}
          initialState={{
            pinnedColumns: {
              left: [
                '__check__',
                'id',
                'name',
                'status',
                'realtime_delivery',
                'action',
              ],
            },
          }}
        />
      </Stack>
    </ThemeProvider>
  );
};
