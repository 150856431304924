import { useEffect, useMemo, useRef } from 'react';
import moment from 'moment';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useCreativeUploadContext } from '../../hooks/useCreativeUploadContext';
import { getDateTime, getMinDateTime, getMaxDateTime } from '../utils';
import { fields as manageFields } from '../constants';
import { fields } from '../../constants';
import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';
import { useGroupSectionContext } from '@components/WizardContainers/GroupSection/hooks/useGroupSectionContext';

export const useManageListElement = ({ field, onUpdate, index }) => {
  const prevFieldsData = useRef();
  const {
    control,
    watch,
    formState: { errors },
    trigger,
    setError,
  } = useFormContext();
  const {
    group: {
      start_date: groupStartDate,
      start_time: groupStartTime,
      end_date: groupEndDate,
      end_time: groupEndTime,
    },
  } = useGroupSectionContext();

  const maxEndDate = getDateTime(groupEndDate, groupEndTime);
  const minStartDate = getDateTime(groupStartDate, groupStartTime);

  const [currentStartDate, currentEndDate] = watch([
    `${fields.draftCreatives.path}.${index}.${manageFields.start_date.path}`,
    `${fields.draftCreatives.path}.${index}.${manageFields.end_date.path}`,
  ]);

  const maxStartDate = getMinDateTime(currentEndDate, groupEndDate);
  const minEndDate = getMaxDateTime(currentStartDate, groupStartDate);

  const { getAdditionalCreativeInfo } = useCreativeUploadContext();

  const trackingPixels = useFieldArray({
    control,
    name: `${fields.draftCreatives.path}.${index}.${manageFields.impression_tracking_pixel.path}`,
  });

  const { releaseCreativeStartEndDatetime: allowDateTime } = useLDFlags();

  const { id } = field;

  useEffect(() => {
    prevFieldsData.current = field;
  }, [field]);

  useEffect(() => {
    const {
      weighting: savedWeighting,
      start_date: savedStartDate,
      end_date: savedEndDate,
    } = prevFieldsData.current;

    getAdditionalCreativeInfo?.({ id }).then(
      ({
        id: creative_id,
        lineitem,
        weighting,
        start_date,
        end_date,
      } = {}) => {
        onUpdate({
          creative_id,
          weighting: weighting ?? savedWeighting,
          lineitem,
          start_date: savedStartDate
            ? moment(savedStartDate)
            : start_date
            ? moment(start_date)
            : null,
          end_date: savedEndDate
            ? moment(savedEndDate)
            : end_date
            ? moment(end_date)
            : null,
          showDateTime: (!!start_date || !!savedStartDate) && allowDateTime,
        });
      }
    );
  }, [id]);

  const showWarningGeneral = useMemo(
    () =>
      field.lineitem_set?.length > 1 ||
      field.lineitem_set?.[0]?.id !== field.lineitem,
    [field]
  );

  const showWarning = {
    clickUrl: showWarningGeneral,
    trackingPixel: showWarningGeneral,
  };

  return {
    trackingPixels,
    showWarning,
    field,
    control,
    trigger,
    errors,
    setError,
    allowDateTime,
    minStartDate,
    maxStartDate,
    minEndDate,
    maxEndDate,
  };
};
